import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const MailLogCheck = {};

MailLogCheck.CheckIpAddress = async (ip, token) =>  {
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.checkIpAddress, { ip : ip, token: token }));
		const item = ref.data.checkIpAddress;
		return ({
			item: item ? item : {},
			error: null,
		});
	}
	catch(err) {
		//console.log(err);
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}

}

MailLogCheck.GetIpAddress = async () =>  {
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getIpAddress));
		const item = ref.data.getIpAddress;
		return ({
			item: item ? item : {},
			error: null,
		});
	}
	catch(err) {
		//console.log(err);
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}


export default MailLogCheck;


