import Amplify from 'aws-amplify';
import AwsExports from './@onocomm-system/include/AwsExports';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import NotFound from './Objects/NotFound';
import Main from './Pages/Main';

Amplify.configure(AwsExports);

const App = () => {


	//ログイン後
	return (
		<BrowserRouter>
			<div>
				<Switch>
					<Route exact path='/' component={Main} />
					{/*<Route path='/signout' component={LoginSignOut}/>*/}
					<Route component={NotFound} />
				</Switch>
			</div>
		</BrowserRouter>
	);
};

export default App;

