
const GraphQL = {
	checkIpAddress :	`mutation checkIpAddress($ip: AWSIPAddress!, $token: String!) {
		checkIpAddress(ip: $ip, token: $token) {
				result
			}
		}`,
	getIpAddress :	`mutation getIpAddress {
			getIpAddress {
				result
			}
		}`,
	};

export default GraphQL;
