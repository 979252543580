import { useEffect, useState } from 'react';
import API from '../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Alert,
	Form,
	Modal,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { FormText, FormButton } from '../Objects/Form';
import { ModalAlert } from '../Objects/Modal';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

const Default = () => {

	const [ip, setIp] = useState(null);
	const [token, setToken] = useState();
	const [alert, setAlert] = useState([]);
	const [load, setLoad] = useState(false);

	useEffect(() => {
	
		const getIpAddress = async () => {
			const ref = await API.MailLogCheck.GetIpAddress();
			const ip = ref.item.result;
			//console.log(ref);
			setIp(ip);
		}

		getIpAddress();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	const handleVerify = (event) =>{
		setToken(event);

	};

	const handleSubmit = async (event) =>{

		try{
			//イベントキャンセル
			event.preventDefault();
			event.stopPropagation();

			setLoad(true);

			const formObj     = event.currentTarget;
			const ip = formObj.ip.value
			//console.log(ip, token);

			const ref = await API.MailLogCheck.CheckIpAddress(ip, token);
			const result = ref.item.result;
			const error = ref.error;

			if(error){
				throw new Error('APIエラー');
			}
			else if(result === 'true'){
				throw new Error('IPアドレスをブロック対象から削除しました。');
			}
			else if(result === 'not fund ip'){
				throw new Error('IPアドレスはブロック対象となっていません。');
			}
			else if(result === 'recaptcha fail'){
				throw new Error('不正な利用を検知しました。');
			}
			else{
				throw new Error(result);
			}
		}
		catch(err){
			setLoad(false);
			setAlert([err.message]);
		}
	};

	const handleClose = () => {
		setAlert([]);
	};


	return (
		<div>
			<ModalAlert data={alert} onClick={handleClose} />
			<Modal size="sm" show={load} centered>
				<Modal.Body className="text-center">
					<img src="/img/loading.gif" />　処理しています
				</Modal.Body>
			</Modal>
			<GoogleReCaptchaProvider reCaptchaKey="6Lf6dVsjAAAAAByUlq2MhD6w2nSrxhP0mhX-9RX0" language="ja">
		    <GoogleReCaptcha onVerify={handleVerify} />
			</GoogleReCaptchaProvider>
			<Container style={{marginTop: '50px'}}>
				<Row>
					<Col md={{ span: 7, offset: 3 }}>
						<h4>メールブロックチェック</h4>
						<Alert>
							本ページではオノコムが提供する<a href="https://www.onocomm.jp/mail.html">共有メールサービス</a>に関するブロック解除及びチェックが行えます。
							ブロック解除後もブロックに至った不正な認証が続く場合は、再度ブロックされる可能性がありますのでご注意ください。
						</Alert>
						<Form onSubmit={handleSubmit}>
							<FormText label={'IPアドレス'} name="ip" value={ip} placeholder="IPアドレスを入力してください" pattern="\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}" required/>
							<br/>
							<FormButton value={'チェック及び解除する'} variant={'dark'} type="submit" />
						</Form>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default withRouter(Default);
