import { useState, useEffect  } from 'react';
import {
	Modal,
	Button,
} from 'react-bootstrap';

const ModalAlert = (props) => {

	const [show, setShow] = useState(false);


	useEffect(() => {
		
		//表示開始
		if(props.data.length){
			setShow(true);
		}
		else{
			setShow(false);
		}
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[props.data]);

	const handleClose = () => {
		setShow(false);
	};

	return (
		<Modal show={show} onHide={props.onClick ? props.onClick : handleClose} centered>
			<Modal.Body>
				{props.data.map((msg, index) => {return (<p key={index} style={{margin: "0px"}}>{msg}</p>);})}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={props.onClick ? props.onClick : handleClose}>
					OK
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export { ModalAlert };

